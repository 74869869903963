<template>
  <div class="elevation-1 white rounded p-3">
    <custom-snackbar ref="snackbar" />

    <v-dialog v-model="userProfileSetupDialog" max-width="900px">
      <v-card>
        <v-toolbar dark color="info">
          <span class="headline">Alterar Perfil</span>
        </v-toolbar>
        <v-form ref="form" lazy-validation>
          <v-card-text>
            <v-container>
              <v-card-text v-if="userProfileSetupLoading">
                <div class="text-center p-5">
                  <h4 class="mb-4">
                    Aguarde...
                  </h4>
                  <v-progress-circular
                    :size="100"
                    :width="2"
                    color="primary"
                    indeterminate
                  />
                </div>
              </v-card-text>
              <div v-else>
                <v-row>
                  <v-col class="p-0">
                    <span class="text-subtitle-1"><strong>Perfil atual: </strong></span>
                    <span class="text-subtitle-1">{{ userProfileSetupItem.perfil ? userProfileSetupItem.perfil.nome : 'Nenhum' }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="p-0">
                    <v-combobox
                      dense
                      outlined
                      class="mt-3"
                      label="Escolher Perfil"
                      v-model="selectedUserProfileSetup"
                      :items="profilesAvaiableData"
                      item-text="nome"
                      item-value="id"
                    />
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="blue darken-1" @click="toggleUserProfileSetupDialog(false)">
              Fechar
            </v-btn>
            <v-btn text color="blue darken-1" @click="handleUserProfileSetup">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-row class="mt-0">
      <v-col class="col-12 col-sm-5">
        <v-select
          dense
          outlined
          v-model="statusFilterValue"
          :items="statusFilterOptions"
          label="Status"
        />
      </v-col>
      <v-col class="col-12 col-sm-5">
        <v-text-field
          dense
          outlined
          v-model="search"
          label="Pesquisar"
          append-icon="mdi-magnify"
        />
      </v-col>
      <v-col class="col-6 col-sm-2">
        <v-btn
          dark
          outlined
          color="info"
          class="v-btn--height-fix w-100"
          @click="syncUsers"
        >
          Sincronizar
          <v-icon dark>
            mdi-cached
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          dense
          sort-by="name"
          :headers="usersHeaders"
          :items="usersData"
          :search="search"
          :items-per-page="$dataTableConfig.getItemsPerPage()"
          :footer-props="$dataTableConfig.getFooterProps()"
          :loading-text="$dataTableConfig.getLoadText()"
          :loading="usersDataLoading"
        >
          <template v-slot:[`item.ativo`]="{ item }">
            <custom-label
              :color="getColor(item.ativo)"
              :text="getAtivoLabel(item.ativo)"
            />
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="manageUserProfileSetupAction(item)">
              mdi-key
            </v-icon>
          </template>

          <template v-slot:no-data>
            Não foi possível listar usuários
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: () => ({
    status: false,
    search: '',
    userProfileSetupItem: {},
    userProfileSetupDialog: false,
    userProfileSetupLoading: false,
    usersData: [],
    usersDataLoading: false,
    profilesAvaiableData: [],
    selectedUserProfileSetup: null,
    statusFilterValue: '',
  }),

  computed: {
    filter: (value) => {
      if (!this.status) {
        return true
      }

      return value < parseInt(this.status)
    },

    filteredData () {
      return this.usersData.filter((d) => d.status === 1)
    },

    usersHeaders () {
      return [
        { text: '#', value: 'id' },
        { text: 'Usuário', value: 'username' },
        { text: 'Perfil', value: 'perfil.nome' },
        { text: 'Status', value: 'ativo', filter: this.statusFilter },
        { text: 'Ações', value: 'actions', sortable: false },
      ]
    },

    permissionsAvailableSelected () {
      return [
        { text: 'Informacoes', value: 'usuario.informacoes' },
      ]
    },

    permissionsAvailable () {
      return [
        { header: 'Usuário Informações', divider: true },
        { text: 'Informacoes', value: 'usuario.informacoes', disabled: false },
      ]
    },

    statusFilterOptions () {
      return [
        { text: 'Todos', value: 'all' },
        { text: 'Ativo', value: true },
        { text: 'Inativo', value: false },
      ]
    },
  },

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Configurações', route: 'configuracoes' },
      { title: 'Sistema', route: 'configuracoes/sistema' },
      { title: 'Usuários' },
    ])

    this.initialize()
  },

  methods: {
    initialize () {
      this.fetchUsers()
      this.fetchProfiles()
    },

    fetchUsers () {
      this.usersDataLoading = true

      ApiService.get('/usuario')
        .then((res) => {
          this.usersData = res.data.data
          this.usersDataLoading = false
        }).catch((error) => {
          console.error('Erro: ', error)
        })
    },

    fetchProfiles () {
      ApiService.get('/perfil')
        .then((res) => {
          this.profilesAvaiableData = res.data.data
        }).catch((error) => {
          console.error('Erro: ', error)
        })
    },

    syncUsers () {
      this.usersDataLoading = true

      ApiService.get('/sapiens/sincronizar-usuario')
        .then(() => {
          this.$refs.snackbar.show(
            'Sincronização concluída',
            '',
          )

          this.fetchUsers()
        })
        .catch((error) => {
          console.error('Erro: ', error)
          this.$refs.snackbar.show('Erro', `Não foi possível sincronizar os usuários. Mensagem: ${JSON.stringify(error.message)}`, 'danger')
          this.usersDataLoading = false
        })
    },

    getColor (status) {
      return status === false ? 'danger' : 'success'
    },

    getAtivoLabel (status) {
      return status === false ? 'Inativo' : 'Ativo'
    },

    statusFilter (value) {
      if (this.statusFilterValue === '' || this.statusFilterValue === 'all') {
        return true
      }

      return value === this.statusFilterValue
    },

    manageUserProfileSetupAction (item) {
      this.userProfileSetupDialog = true
      this.userProfileSetupItem = item
    },

    toggleUserProfileSetupDialog (show = true) {
      this.userProfileSetupDialog = show
    },

    handleUserProfileSetup () {
      this.userProfileSetupLoading = true

      ApiService.put(`/usuario/${this.userProfileSetupItem.id}`, {
        perfil_id: this.selectedUserProfileSetup.id,
      }).then(() => {
        this.$refs.snackbar.show('Sucesso', 'Perfil atribuido com sucesso')
        this.toggleUserProfileSetupDialog(false)
      }).catch((error) => {
        this.$refs.snackbar.show('Erro', `Não foi possível atribuir o perfil ao usuário. Mensagem: ${JSON.stringify(error.message)}`, 'danger')
      }).finally(() => {
        this.userProfileSetupLoading = false
        this.selectedUserProfileSetup = null
        this.fetchUsers()
      })
    },
  },
}
</script>
